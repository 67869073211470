import React from 'react';
import DashboardContent from '../components/DashboardContent';
import DashboardLayout from '../components/DashboardLayout';

interface DashboardLayoutProps {
  children: React.ReactNode;
}

export default function Dashboard() {
  return (
    <DashboardLayout>
        <DashboardContent />
    </DashboardLayout>
  );
}