import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import SplashScreen from './components/Splashscreen';
import './App.css';
import Login from './pages/login';
import Dashboard from './pages/dashboard';
import Inscription from './pages/inscription';
import Profile from './pages/profile';
import Offres from './pages/offres';
import Abonement from './pages/abonement';
import EmailVerification from './components/EmailVerification';
import ResetPassword from './pages/resetpassword';

const AppContent: React.FC = () => {
  const { isValidatingToken } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!isValidatingToken) {
      const timer = setTimeout(() => setIsLoading(false), 1000); 
      return () => clearTimeout(timer);
    }
  }, [isValidatingToken]);

  if (isLoading || isValidatingToken) {
    return <SplashScreen />;
  }

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
        <Route path="/signup" element={<PublicRoute><Inscription /></PublicRoute>} />
        <Route path="/forgot-password" element={<PublicRoute><ResetPassword /></PublicRoute>} />
        <Route
          path="/verify-email"
          element={
            <PrivateRoute>
              <EmailVerification />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path="/offres"
          element={
            <PrivateRoute>
              <Offres />
            </PrivateRoute>
          }
        />
        <Route
          path="/abonement"
          element={
            <PrivateRoute>
              <Abonement />
            </PrivateRoute>
          }
        />
        <Route path="/" element={<Navigate to="/dashboard" replace />} />
      </Routes>
    </Router>
  );
};

const App: React.FC = () => {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
};

export default App;