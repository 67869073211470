
import DashboardLayout from '../components/DashboardLayout';
import ProfileContent from '../components/ProfileContent';



export default function Profile() {
  return (
    <DashboardLayout>
        <ProfileContent />
    </DashboardLayout>
  );
}