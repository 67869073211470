

export default function  Splashscreen() {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="text-center">
        <img
          src="/dabablane-logo.png"
          alt="DABABLANE"
          className="w-50  mx-auto mb-4 animate-pulse"
        />
      </div>
    </div>
  );
};

