import React from 'react';
import { Star, Heart, Zap } from 'lucide-react';
import config from '../config/config';

interface OfferTileProps {
  id: string;
  image: string;
  title: string;
  merchant: {
    name: string;
  };
  original_price: number;
  reduced_price: number;
  rating: number;
  review_count: number;
  modality: string;
  end_date: string;
  distance: string;
  persons_per_session: number | null;
  condition: 'product' | 'service' | 'delivery';
}

export default function OfferTile({
  id,
  image,
  title,
  merchant,
  original_price,
  reduced_price,
  rating,
  review_count,
  end_date,
  distance,
  modality,
  persons_per_session,
  condition
}: OfferTileProps) {
  const discount = original_price - reduced_price;
  const expireTime = calculateExpireTime(end_date);

  const getDiscountText = () => {
    if (condition === 'service' && persons_per_session) {
      return `${reduced_price} DH par personne et -${discount.toFixed(0)}...`;
    } else {
      return `${reduced_price} DH de réduction`;
    }
  };

  const modalities = {
    'price': {
      color: "#00C4CB",
      label: "Réduction"
    },
    'advantage': {
      color: "#FFBE5D",
      label: "Avantage"
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden h-32 flex inline-flex">
      <div className="flex">
        <div className="relative w-32 flex-shrink-0">
          <img src={`${config.endpoints.imgBlane}/${image}`} alt={title} className="w-full h-full object-cover" />
          <div className="absolute bottom-2 left-2 flex space-x-2">
            <div className="bg-white p-1 rounded-full">
              <Heart className="w-4 h-4 text-red-500" />
            </div>
            <div className="bg-white p-1 rounded-full">
              <Zap className="w-4 h-4 text-yellow-500" />
            </div>
          </div>
        </div>
        <div className="p-3 w-64 flex flex-col justify-between">
          <div>
            <h3 className="text-lg font-semibold leading-tight truncate">{title}</h3>
            <p className="text-sm text-gray-600 truncate">{merchant.name}</p>
            <div className="flex items-center mt-1">
              <div className="flex items-center">
                {[...Array(5)].map((_, i) => (
                  <Star key={i} className={`w-3 h-3 ${i < Math.floor(rating) ? 'text-yellow-400' : 'text-gray-300'}`} fill="currentColor" />
                ))}
              </div>
              <span className="text-sm text-gray-600 ml-1">{rating.toFixed(1)}</span>
              <span className="text-sm text-gray-400 ml-1">({review_count || 0})</span>
            </div>
          </div>
          <div className="flex justify-between items-end">
            <div>
              <span className="text-gray-500 line-through text-sm">{original_price} DH</span>
              <div className="text-2xl font-bold text-teal-600">{reduced_price} DH</div>
            </div>
            <div className="text-right">
              <div className="text-xs text-gray-500">Expire dans</div>
              <div className="text-sm font-medium text-red-500">{expireTime}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-8 flex-shrink-0 border-l-2 border-dashed border-gray-300 flex items-center justify-center">
        <span className={`transform -rotate-90 whitespace-nowrap text-xs`}  style={{ color: modalities[modality].color }}>
          {modalities[modality].label}
        </span>
      </div>
    </div>
  );
}

function calculateExpireTime(endDate: string): string {
  const end = new Date(endDate);
  const now = new Date();
  const diff = end.getTime() - now.getTime();
  
  if (diff <= 0) {
    return "Expiré";
  }

  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((diff % (1000 * 60)) / 1000);

  return `${days.toString().padStart(2, '0')}:${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}