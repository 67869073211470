import React, { useState, useRef, useEffect } from 'react';
import { X, Calendar, Clock, Users } from 'lucide-react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar as PrimeCalendar } from 'primereact/calendar';
import { BlockUI } from 'primereact/blockui';
import { Toast } from 'primereact/toast';
import api from '../utils/api';
import CustomToggleButton from './CustomToggleButton';

interface NewBlaneModalProps {
  onClose: () => void;
  onSubmit: (formData: any) => void;
}

export default function NewBlaneModal({ onClose, onSubmit }: NewBlaneModalProps) {
  const [formData, setFormData] = useState({
    title: '',
    category: '',
    description: '',
    condition: 'product',
    modality: 'price',
    reservation_type	: 'immediate' as 'immediate' | 'pre_booked' ,
    original_price: null as number | null,
    reduced_price: null as number | null,
    max_quantity: null as number | null,
    start_date: null as Date | null,
    end_date: null as Date | null,
    payment: {
      online: true,
      partial: false,
      on_site: false,
    },
    duration: '',
    persons_per_session: null as number | null,
    max_reservations: null as number | null,
    max_reservations_per_slot: null as number | null,
    available_slots: '',
  });

  const [errors, setErrors] = useState<Record<string, string>>({});
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [categories, setCategories] = useState<{ label: string; value: string }[]>([]);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useRef<Toast>(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await api.get('/merchant/categories');
        setCategories(response.data);
      } catch (error) {
        console.error('Failed to fetch categories:', error);
        toast.current?.show({ severity: 'error', summary: 'Erreur', detail: 'Impossible de charger les catégories' });
      }
    };

    fetchCategories();
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    console.log( { name, value } );

    setFormData(prev => ({ ...prev, [name]: value }));
    setErrors(prev => ({ ...prev, [name]: '' }));
  };

  const handleNumberChange = (e: { value: number | null }, name: string) => {
    setFormData(prev => ({ ...prev, [name]: e.value }));
    setErrors(prev => ({ ...prev, [name]: '' }));
  };

  const handleDateChange = (e: { value: Date | null | undefined }, name: string) => {
    setFormData(prev => ({ ...prev, [name]: e.value }));
    setErrors(prev => ({ ...prev, [name]: '' }));
  };

  const handleToggleChange = (name: string) => {
    setFormData(prev => ({
      ...prev,
      payment: { ...prev.payment, [name]: !prev.payment[name as keyof typeof prev.payment] }
    }));
  };


  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitting(true);
      try {
        const formDataToSend = new FormData();
        Object.entries(formData).forEach(([key, value]) => {
          if (key === 'payment') {
            formDataToSend.append(key, JSON.stringify(value));
          } else if (value instanceof Date) {
            formDataToSend.append(key, value.toISOString());
          } else {
            formDataToSend.append(key, value as string);
          }
        });
        if (selectedImage) {
          formDataToSend.append('image', selectedImage);
        }

        const response = await api.post('/merchant/blanes/add', formDataToSend, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        onSubmit(response.data);
        toast.current?.show({ severity: 'success', summary: 'Succès', detail: 'BLANE créé avec succès' });
        onClose();
      } catch (error: any) {
        if (error.response && error.response.data && error.response.data.errors) {
          setErrors(error.response.data.errors);
        }
        toast.current?.show({ severity: 'error', summary: 'Erreur', detail: 'Impossible de créer le BLANE' });
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast.current?.show({ severity: 'error', summary: 'Erreur', detail: 'Veuillez corriger les erreurs dans le formulaire' });
    }
  };

  const validateForm = () => {
    const newErrors: Record<string, string> = {};
    if (!formData.title) newErrors.title = 'Le titre est requis';
    if (!formData.category) newErrors.category = 'La catégorie est requise';
    if (!formData.description) newErrors.description = 'La description est requise';
    if (formData.original_price === null) newErrors.original_price = 'Le prix original est requis';
    if (formData.reduced_price === null) newErrors.reduced_price = 'Le prix réduit est requis';
    if (!formData.start_date) newErrors.start_date = 'La date de début est requise';
    if (!formData.end_date) newErrors.end_date = 'La date de fin est requise';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };



  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 overflow-y-auto">
      <Toast ref={toast} />
      <BlockUI blocked={isSubmitting}>
      <div className="bg-white rounded-lg p-6 w-full max-w-6xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">Création d'un nouveau BLANE !</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="w-6 h-6" />
          </button>
        </div>
        <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="space-y-4">
            <div className=" material-input " style={{marginBottom:20}} >
              <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">Titre</label>
              <InputText
                id="title"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                className={`w-full bg-white border border-gray-300 rounded-md ${errors.title ? 'p-invalid border-red-500' : ''}`}
              />
              {errors.title && <small className="p-error">{errors.title}</small>}
            </div>

              <div className=" material-dropdown" >
              <label htmlFor="category" className="block text-sm font-medium text-[#616161] mb-1">Catégorie</label>
               <Dropdown
                id="category"
                name="category"
                optionLabel="name"
                optionValue="category_id"
                value={formData.category}
                options={categories}
                onChange={(e) => handleInputChange({ target: { name: 'category', value: e.value } } as React.ChangeEvent<HTMLInputElement>)}
                className={`w-full bg-white border border-gray-300 text-[#616161] rounded-md ${errors.category ? 'p-invalid border-red-500' : ''}`}
                placeholder="Sélectionnez une catégorie"
              />
              {errors.category && <small className="p-error">{errors.category}</small>}
            </div>

            <div className='material-textarea'>
              <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">Description du blane</label>
              <InputTextarea
                id="description"
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                rows={3}
                className={`w-full bg-white border border-gray-300 rounded-md ${errors.description ? 'p-invalid border-red-500' : ''}`}
              />
              {errors.description && <small className="p-error">{errors.description}</small>}
            </div>

            <div>
              <span className="block text-sm font-medium text-gray-700 mb-2">Condition du blane</span>
              <div className="flex space-x-4">

                {[
                  { key: 'product', label: 'Produit' },
                  { key: 'service', label: 'Service' },
                  { key: 'delivery', label: 'Livraison' },
                ].map((option) => (
                  <div key={option.key} className="flex items-center">
                    <RadioButton
                      inputId={`condition_${option}`}
                      name="condition"
                      className={`${formData.condition===option.key?'csut-p-radiobutton':''}`}
                      value={option.key}
                      onChange={(e) => handleInputChange({ target: { name: 'condition', value: e.value } } as React.ChangeEvent<HTMLInputElement>)}
                      checked={formData.condition === option.key}
                    />
                    <label htmlFor={`condition_${option}`} className="ml-2">{option.label}</label>
                  </div>
                ))}
              </div>
            </div>

            <div>
              <span className="block text-sm font-medium text-gray-700 mb-2">Modalité du blane</span>
              <div className="flex space-x-4">
              {[
                  { key: 'price', label: 'Prix' },
                  { key: 'advantage', label: 'Avantage' },
                ].map((option) => (
                  <div key={option.key} className="flex items-center">
                    <RadioButton
                      inputId={`modality_${option.key}`}
                      name="modality"
                      className={`${formData.modality===option.key?'csut-p-radiobutton':''}`}
                      value={option.key}
                      onChange={(e) => handleInputChange({ target: { name: 'modality', value: e.value } } as React.ChangeEvent<HTMLInputElement>)}
                      checked={formData.modality === option.key}
                    />
                    <label htmlFor={`modality_${option}`} className="ml-2">{option.label}</label>
                  </div>
                ))}
              </div>
            </div>

            <div>
              <span className="block text-sm font-medium text-gray-700 mb-2">Type de réservation</span>
              <div className="flex space-x-4">
              {[                 
                  { key: 'immediate', label: 'Instantané' },
                  { key: 'pre_booked', label: 'Pré-réservation' },
                ].map((option) => (
                  <div key={option.key} className="flex items-center">
                    <RadioButton
                      inputId={`reservation_type_${option.key}`}
                      name="reservation_type"
                      className={`${formData.reservation_type===option.key?'csut-p-radiobutton':''}`}
                      value={option.key}
                      onChange={(e) => handleInputChange({ target: { name: 'reservation_type', value: e.value } } as React.ChangeEvent<HTMLInputElement>)}
                      checked={formData.reservation_type === option.key}
                    />
                    <label htmlFor={`reservation_type_${option.key}`} className="ml-2">{option.label}</label>
                  </div>
                ))}
              </div>
            </div>

            <div className="flex justify-between space-x-2">
              <div className="w-[32%] material-input" >
                 <label htmlFor="original_price" className="block text-sm font-medium text-center text-gray-700 mb-1">Prix original</label>
                <InputNumber
                  id="original_price"
                  value={formData.original_price}
                  inputClassName='w-full'
                  onValueChange={(e) => handleNumberChange(e, 'original_price')}
                  mode="currency"
                  currency="MAD"
                  locale="fr-FR"
                  className={`bg-white border border-gray-300 rounded-md ${errors.original_price ? 'p-invalid border-red-500' : ''}`}
                />
                {errors.original_price && <small className="p-error">{errors.original_price}</small>}
              </div>
              
              <div className="w-[32%] material-input" >
                <label htmlFor="reduced_price" className="block text-sm font-medium text-gray-700 mb-1">Prix réduit</label>
                <InputNumber
                  id="reduced_price"
                  inputClassName='w-full'
                  value={formData.reduced_price}
                  onValueChange={(e) => handleNumberChange(e, 'reduced_price')}
                  mode="currency"
                  currency="MAD"
                  locale="fr-FR"
                  className={`bg-white border border-gray-300 rounded-md ${errors.reduced_price ? 'p-invalid border-red-500' : ''}`}
                />
                {errors.reduced_price && <small className="p-error">{errors.reduced_price}</small>}
              </div>
              
              <div className="w-[32%] material-input" >
                <label htmlFor="max_quantity" className="block text-sm font-medium text-gray-700 mb-1">Quantité max</label>
                <InputNumber
                  id="max_quantity"
                  inputClassName='w-full'
                  value={formData.max_quantity}
                  onValueChange={(e) => handleNumberChange(e, 'max_quantity')}
                  className={`bg-white border border-gray-300 rounded-md ${errors.max_quantity ? 'p-invalid border-red-500' : ''}`}
                />
              </div>
            </div>


            <div className="grid grid-cols-2 gap-4">
              <div className='material-calendar'>
                <label htmlFor="start_date" className="block text-sm font-medium text-gray-700 mb-1">Date début</label>
                <PrimeCalendar
                  id="start_date"
                  value={formData.start_date}
                  onChange={(e) => handleDateChange(e, 'start_date')}
                  showIcon
                  className={` bg-white border border-gray-300 rounded-md ${errors.start_date ? 'p-invalid border-red-500' : ''}`}
                />
                {errors.start_date && <small className="p-error">{errors.start_date}</small>}
              </div>
              <div className='material-calendar'>
                <label htmlFor="end_date" className="block text-sm font-medium text-gray-700 mb-1">Date fin</label>
                <PrimeCalendar
                  id="end_date"
                  value={formData.end_date}
                  onChange={(e) => handleDateChange(e, 'end_date')}
                  showIcon
                  className={` bg-white border border-gray-300 rounded-md ${errors.end_date ? 'p-invalid border-red-500' : ''}`}
                />
                {errors.end_date && <small className="p-error">{errors.end_date}</small>}
              </div>
            </div>

            <div>
              <span className="block text-sm font-medium text-gray-700 mb-2">Moyen de paiement</span>
              <div className="gap-1 flex">
                {[
                  { key: 'online', label: 'En ligne' },
                  { key: 'partial', label: 'Partiel', premium: true },
                  { key: 'on_site', label: 'Sur place', premium: true }
                ].map(({ key, label, premium }) => (
                  <div key={key} className="flex items-center custom-toggle-wrapper">
                    <CustomToggleButton
                      onLabel={label}
                      offLabel={label}
                      onChange={() => handleToggleChange(key)}
                      checked={formData.payment[key as keyof typeof formData.payment]}
                       className={`w-36 `}
                    />
                   
                    {premium && <span className=" text-yellow-500">👑</span>}
                  </div>
                ))}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div className='material-input'>
                <label htmlFor="duration"   className="block text-sm font-medium text-gray-700 mb-1">Durée de la prestation</label>
                <InputText
                  id="duration"
                  name="duration"
                  value={formData.duration}
                  onChange={handleInputChange}
                  className={`w-full bg-white border border-gray-300 rounded-md ${errors.duration ? 'p-invalid border-red-500' : ''}`}
                />
              </div>
              <div className='material-input'>
                <label htmlFor="persons_per_session" className="block text-sm font-medium text-gray-700 mb-1">Personne / prestation</label>
                <InputNumber
                  id="persons_per_session"
                  value={formData.persons_per_session}
                  onValueChange={(e) => handleNumberChange(e, 'persons_per_session')}
                  className={`w-full bg-white border border-gray-300 rounded-md ${errors.persons_per_session ? 'p-invalid border-red-500' : ''}`}
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div className='material-input'>
                
                <label htmlFor="max_reservations" className="block text-sm font-medium text-gray-700 mb-1">Nombre max de réservation</label>
                <InputNumber
                  id="max_reservations"
                  value={formData.max_reservations}
                  onValueChange={(e) => handleNumberChange(e, 'max_reservations')}
                  className={`w-full bg-white border border-gray-300 rounded-md ${errors.max_reservations ? 'p-invalid border-red-500' : ''}`}
                />
              </div>
              <div className='material-input'>
                <label htmlFor="max_reservations_per_slot" className="block text-sm font-medium text-gray-700 mb-1">Max de réservation / créneau</label>
                <InputNumber
                  id="max_reservations_per_slot"
                  value={formData.max_reservations_per_slot}
                  onValueChange={(e) => handleNumberChange(e, 'max_reservations_per_slot')}
                  className={`w-full bg-white border border-gray-300 rounded-md ${errors.max_reservations_per_slot ? 'p-invalid border-red-500' : ''}`}
                />
              </div>
            </div>

            <div className='material-input'>
              <label htmlFor="max_reservations_per_slot" className="block text-sm font-medium text-gray-700 mb-1">Créneaux Disponibles</label>
              <InputText
                id="available_slots"
                name="available_slots"
                value={formData.available_slots}
                onChange={handleInputChange}
                className={`w-full bg-white border border-gray-300 rounded-md ${errors.available_slots ? 'p-invalid border-red-500' : ''}`}
              />
            </div>

        

          </div>

          <div className="space-y-4">
            <div className="bg-gray-100 p-4 rounded-lg">
              <div className="relative h-48 bg-gray-200 rounded-lg overflow-hidden">
                {previewImage ? (
                  <img src={previewImage} alt="Preview" className="w-full h-full object-cover" />
                ) : (
                  <div className="flex items-center justify-center h-full">
                    <span className="text-gray-500">CHARGER PHOTO/VIDEO</span>
                  </div>
                )}
                <input
                  type="file"
                  accept="image/*,video/*"
                  onChange={handleImageUpload}
                  className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                />
              </div>
              <h3 className="mt-4 text-xl font-semibold">{formData.title || 'Titre du BLANE'}</h3>
              <p className="mt-2 text-gray-600">
                {formData.description || 'Description du BLANE'}
              </p>
              <div className="mt-4">
                <span className="text-3xl font-bold text-teal-600">{formData.reduced_price || 0} DH</span>
              </div>
              <div className="mt-4 space-y-2">
                <div className="flex items-center">
                  <span className="text-gray-600 mr-2">Nombre de personne</span>
                  <div className="flex items-center">
                    <button type="button" className="bg-gray-200 text-gray-600 hover:bg-gray-300 h-8 w-8 rounded-l-lg flex items-center justify-center">-</button>
                    <span className="bg-white h-8 w-8 flex items-center justify-center border-t border-b">1</span>
                    <button type="button" className="bg-gray-200 text-gray-600 hover:bg-gray-300 h-8 w-8 rounded-r-lg flex items-center justify-center">+</button>
                  </div>
                </div>
                <div className="flex items-center">
                  <Clock className="h-5 w-5 text-gray-400 mr-2" />
                  <span className="text-gray-600">Durée : {formData.duration || '1h'}</span>
                </div>
                <div className="flex items-center">
                  <Users className="h-5 w-5 text-gray-400 mr-2" />
                  <span className="text-gray-600">Nombre max de personnes : {formData.persons_per_session || 5}</span>
                </div>
                <div className="flex items-center">
                  <Calendar className="h-5 w-5 text-gray-400 mr-2" />
                  <span className="text-gray-600">Date limite</span>
                </div>
                <div className="text-gray-600">{formData.end_date ? formData.end_date.toLocaleDateString() : '31 Mai 2024'} ( 4 jours restants)</div>
              </div>
              <button type="submit" className="mt-4 w-full bg-teal-600 text-white py-2 px-4 rounded-lg hover:bg-teal-700 transition-colors">
                CRÉER LE BLANE
              </button>
            </div>
          </div>
        </form>
      </div>
      </BlockUI>
    </div>
  );
}