import React from 'react';
import { Star, Crown } from 'lucide-react';
import { PieChart, Pie, Cell, LineChart, Line, XAxis, YAxis, ResponsiveContainer } from 'recharts';

export default function DashboardContent() {
  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Dashboard</h1>
        <div className="flex space-x-4">
          <select className="border rounded-md px-4 py-2">
            <option>Catégorie: Tout</option>
          </select>
          <select className="border rounded-md px-4 py-2">
            <option>Période: Tout</option>
          </select>
        </div>
      </div>

      {/* Activity Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
        <MetricCard title="CA Réalisé" value="245.670" subtitle="MAD" />
        <MetricCard title="CA Potentiel" value="78.000" subtitle="MAD" />
        <MetricCard subtitle={""} title="Nombre de réservations" value="340.110" />
        <OnlineOfflineCard online={60} offline={40} />
        <StatusCard value="500" subtitle="MAD" status="NOUVEAU" />
      </div>

      {/* Statistics Section */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-6">
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Statistiques</h2>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <h3 className="text-sm text-gray-500">Evaluations & Commentaires</h3>
              <p className="text-2xl font-bold">95% (356)</p>
            </div>
            <div>
              <h3 className="text-sm text-gray-500">Moyens de paiement</h3>
              <PieChartComponent />
            </div>
          </div>
        </div>
        
        <div className="bg-white p-6 rounded-lg shadow">
          <div className="flex items-center mb-4">
            <h2 className="text-xl font-semibold">Préreservation</h2>
            <Crown className="ml-2 text-yellow-400" />
          </div>
          <div className="grid grid-cols-3 gap-4">
            <StatCard title="% de pré réservation confirmées" value={80} />
            <StatCard title="% Pré réservation Flashées" value={80} />
            <StatCard title="% de réactivité" value={50} />
          </div>
        </div>
      </div>

      {/* Charts Section */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-6">
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">CA Réalisé</h2>
          <ResponsiveContainer width="100%" height={200}>
            <LineChart data={generateLineData()}>
              <Line type="monotone" dataKey="value" stroke="#0D9488" />
              <XAxis dataKey="name" />
              <YAxis />
            </LineChart>
          </ResponsiveContainer>
        </div>
        
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Nombre de réservation</h2>
          <ResponsiveContainer width="100%" height={200}>
            <LineChart data={generateLineData()}>
              <Line type="monotone" dataKey="value" stroke="#F87171" />
              <XAxis dataKey="name" />
              <YAxis />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}

function MetricCard({ title, value, subtitle }) {
  return (
    <div className="bg-white p-4 rounded-lg shadow">
      <h3 className="text-sm text-gray-500">{title}</h3>
      <p className="text-2xl font-bold">{value}</p>
      {subtitle && <p className="text-sm text-teal-600">{subtitle}</p>}
    </div>
  );
}

function OnlineOfflineCard({ online, offline }) {
  return (
    <div className="bg-white p-4 rounded-lg shadow">
      <h3 className="text-sm text-gray-500">% de flash</h3>
      <div className="flex justify-between mt-2">
        <div>
          <p className="text-lg font-bold">{online}%</p>
          <p className="text-sm text-gray-500">Online</p>
        </div>
        <div>
          <p className="text-lg font-bold">{offline}%</p>
          <p className="text-sm text-gray-500">Offline</p>
        </div>
      </div>
    </div>
  );
}

function StatusCard({ value, subtitle, status }) {
  return (
    <div className="bg-white p-4 rounded-lg shadow">
      <h3 className="text-sm text-gray-500">Panier moyen</h3>
      <p className="text-2xl font-bold">{value}</p>
      <p className="text-sm text-teal-600">{subtitle}</p>
      <div className="mt-2">
        <span className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
          <Star className="w-3 h-3 mr-1" />
          {status}
        </span>
      </div>
    </div>
  );
}

function StatCard({ title, value }) {
  return (
    <div>
      <h3 className="text-sm text-gray-500">{title}</h3>
      <div className="mt-2 relative pt-1">
        <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-teal-200">
          <div style={{ width: `${value}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-teal-500"></div>
        </div>
        <p className="text-lg font-bold">{value}%</p>
      </div>
    </div>
  );
}

function PieChartComponent() {
  const data = [
    { name: 'Online', value: 676, color: '#0D9488' },
    { name: 'Offline', value: 492, color: '#F87171' },
    { name: 'Partiel', value: 630, color: '#FCD34D' },
  ];

  return (
    <ResponsiveContainer width="100%" height={100}>
      <PieChart>
        <Pie
          data={data}
          innerRadius={25}
          outerRadius={40}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}

function generateLineData() {
  const months = ['JAN', 'FEV', 'MAR', 'AVR', 'MAI', 'JUI', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  return months.map(month => ({
    name: month,
    value: Math.floor(Math.random() * 750) + 250,
  }));
}