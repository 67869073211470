import React from 'react'
import { Button } from 'primereact/button'
const AbonnementPremium: React.FC = () => {
    return (

        <div className="flex-1 overflow-auto">
          <div className="p-8">
            <h1 className="text-3xl font-bold mb-8">Abonnement premium</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h2 className="text-2xl font-bold mb-2">Freemium</h2>
                <p className="text-gray-600 mb-4">Utiliser ce plan comme vous le souhaitez</p>
                <ul className="space-y-2">
                  <li className="flex items-center">
                    <i className="pi pi-check text-purple-600 mr-2"></i>
                    Inscription gratuite
                  </li>
                  <li className="flex items-center">
                    <i className="pi pi-check text-purple-600 mr-2"></i>
                    Tableau de Suivi Activité Complet
                  </li>
                  <li className="flex items-center">
                    <i className="pi pi-check text-purple-600 mr-2"></i>
                    2 options de paiement
                  </li>
                  <li className="flex items-center">
                    <i className="pi pi-check text-purple-600 mr-2"></i>
                    4 Blanes Gratuits par moi
                  </li>
                  <li className="flex items-center">
                    <i className="pi pi-check text-purple-600 mr-2"></i>
                    Option Préreservation gratuite 3 mois
                  </li>
                  <li className="flex items-center">
                    <i className="pi pi-check text-purple-600 mr-2"></i>
                    Commission sur transaction uniquement
                  </li>
                </ul>
                  <Button label="Vous êtes déjà inscrit" className="mt-6  rounded-full px-4 mx-auto bg-gray-200 p-2 px-4 mx-auto  p-button-outlined p-button-secondary" disabled />
              </div>
              <div className="bg-teal-600 p-6 rounded-lg shadow-md text-white relative overflow-hidden">
                <div className="absolute top-4 right-4 bg-coral-500 text-white rounded-full px-2 py-1 text-sm font-semibold">
                  RECOMMENDED
                </div>
                <h2 className="text-2xl font-bold mb-2">Premium</h2>
                <ul className="space-y-2">
                  <li className="flex items-center">
                    <i className="pi pi-check mr-2"></i>
                    Publications & blanes illimitées
                  </li>
                  <li className="flex items-center">
                    <i className="pi pi-check mr-2"></i>
                    Option paiement sur place disponible
                  </li>
                  <li className="flex items-center">
                    <i className="pi pi-check mr-2"></i>
                    Accès au profil des clients
                  </li>
                  <li className="flex items-center">
                    <i className="pi pi-check mr-2"></i>
                    Limiter les Blanes aux clients avec statut Master
                  </li>
                  <li className="flex items-center">
                    <i className="pi pi-check mr-2"></i>
                    Option Préreservation illimitée
                  </li>
                  <li className="flex items-center">
                    <i className="pi pi-check mr-2"></i>
                    Commission sur transaction
                  </li>
                  <li className="flex items-center">
                    <i className="pi pi-check mr-2"></i>
                    Visibilité préférentielle du commerçant
                  </li>
                  <li className="flex items-center">
                    <i className="pi pi-check mr-2"></i>
                    Option 2ème chance
                  </li>
                </ul>
                  <Button label="Choisir ce plan (100 DH / Mois)" className="mt-6 p-2 rounded-full px-4 mx-auto bg-white text-teal-600 p-button-outlined" />
                
                <img src="/premium_girl.svg" alt="Premium illustration" className="absolute bottom-0 right-0 h-200 opacity-80" />
              </div>
            </div>
          </div>
        </div>

    )
  }
  
  export default AbonnementPremium