import React, { useState } from 'react';
import styles from '../styles/CustomToggleButton.module.css';

interface CustomToggleButtonProps {
  onLabel?: string;
  offLabel?: string;
  onChange?: (checked: boolean) => void;
  checked?: boolean;
  className?: string;
}

export default function CustomToggleButton({
  onLabel = 'En ligne',
  offLabel = 'Hors ligne',
  onChange,
  checked: propChecked,
  className = '',
}: CustomToggleButtonProps) {
  const [checked, setChecked] = useState(propChecked || false);

  const handleToggle = () => {
    const newChecked = !checked;
    setChecked(newChecked);
    if (onChange) {
      onChange(newChecked);
    }
  };

  return (
    <div className={`${styles.customToggle} ${className}`} onClick={handleToggle}>
      <div className={`${styles.toggleSwitch} ${checked ? styles.checked : ''}`}>
        <div className={styles.toggleSlider}></div>
      </div>
      <span className={styles.toggleLabel}>{checked ? onLabel : offLabel}</span>
    </div>
  );
}