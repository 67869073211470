const config = {
    apiUrl: 'https://api.dabablane.icamob.ma/api',
    endpoints: {
      imgAvatar: 'https://api.dabablane.icamob.ma/merchant/avatar',
      imgBanner: 'https://api.dabablane.icamob.ma/merchant/banner',
      imgBlane: 'https://api.dabablane.icamob.ma/blane',
      signup: '/signup/merchant',
      login: '/signin',
      user: '/user',
    },
    mapBoxToken:'pk.eyJ1IjoidW5lcyIsImEiOiJjbHM0cWtlOTMwOWZhMnBvMHFxeDV1N3piIn0._t0RpNVF2pmN48ofIT8Dig',
  };
  
  export default config;