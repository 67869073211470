import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../utils/api';
import { Toast } from 'primereact/toast';
import { useAuth } from '../contexts/AuthContext';

export default function EmailVerification() {
  const [verificationPin, setVerificationPin] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useRef<Toast>(null);
  const { user, updateUser } = useAuth();

  const handleVerification = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await api.post('/verify-email', { email: user?.email, pin: verificationPin });
      console.log('Email verification successful', response.data);
      toast.current?.show({severity: 'success', summary: 'Succès', detail: 'Email vérifié avec succès'});
      updateUser({ isEmailVerified: true });
      navigate('/dashboard'); // Or wherever you want to redirect after successful verification
    } catch (error) {
      console.error('Email verification failed', error);
      toast.current?.show({severity: 'error', summary: 'Erreur', detail: 'Code PIN invalide'});
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendPin = async () => {
    try {
      const response = await api.post('/resend-verification-email');
      toast.current?.show({severity: 'success', summary: 'Succès', detail: 'Nouveau code PIN envoyé'});
    } catch (error) {
      console.error('Failed to resend PIN', error);
      toast.current?.show({severity: 'error', summary: 'Erreur', detail: 'Échec de l\'envoi du nouveau code PIN'});
    }
  };

  return (
    <div className="flex min-h-screen">
      <Toast ref={toast} />
      {/* Pattern Side */}
      <div className="hidden lg:flex lg:w-1/2 bg-teal-600 relative overflow-hidden">
        <div className="absolute inset-0" style={{
          backgroundImage: "url('/pattern.png')",
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }} />
      </div>

      {/* Email Verification Form Side */}
      <div className="w-full lg:w-1/2 flex flex-col justify-center px-4 sm:px-6 lg:px-8 py-12">
        <div className="max-w-md w-full mx-auto">
          <div className="mb-6">
            <img
                  src="/dabablane-logo.png"
                  alt="DABABLANE"
                  className="w-50  mx-auto mb-4 "
                />
          </div>
          
          <h2 className="text-2xl font-bold mb-8 text-gray-900">
            Vérifiez votre email
          </h2>
          
          <form onSubmit={handleVerification} className="space-y-6">
            <div>
              <label htmlFor="verificationPin" className="block text-sm font-medium text-gray-700">
                Code PIN
              </label>
              <input
                type="text"
                id="verificationPin"
                name="verificationPin"
                value={verificationPin}
                onChange={(e) => setVerificationPin(e.target.value)}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-teal-500 focus:outline-none focus:ring-teal-500"
                maxLength={6}
                placeholder="Entrez le code à 6 chiffres"
              />
            </div>
            <button
              type="submit"
              disabled={isLoading}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 disabled:opacity-50"
            >
              {isLoading ? 'Vérification...' : 'Vérifier'}
            </button>
          </form>

          <div className="mt-4 text-center">
            <button
              onClick={handleResendPin}
              className="text-sm text-teal-600 hover:text-teal-500"
            >
              Renvoyer le code PIN
            </button>
          </div>

          <div className="mt-6">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">Ou</span>
              </div>
            </div>

            <div className="mt-6">
              <button
                type="button"
                onClick={() => navigate('/login')}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-coral-500 hover:bg-coral-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-coral-500"
              >
                Retour à la connexion
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}