import React, { useState, useRef } from 'react';
import { Eye, EyeOff } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import api from '../utils/api';
import { Toast } from 'primereact/toast';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [pin, setPin] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState('request'); // 'request', 'reset', 'success'
  const navigate = useNavigate();
  const toast = useRef<Toast>(null);

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    if (!email.trim()) newErrors.email = 'L\'adresse e-mail est requise';
    else if (!/\S+@\S+\.\S+/.test(email)) newErrors.email = 'L\'adresse e-mail est invalide';
    if (step === 'reset') {
      if (!pin.trim()) newErrors.pin = 'Le code PIN est requis';
      if (!newPassword) newErrors.newPassword = 'Le nouveau mot de passe est requis';
      else if (newPassword.length < 8) newErrors.newPassword = 'Le mot de passe doit contenir au moins 8 caractères';
      if (newPassword !== confirmPassword) newErrors.confirmPassword = 'Les mots de passe ne correspondent pas';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleResetRequest = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);
    try {
      await api.post('/forgot-password/merchant', { email });
      toast.current?.show({severity: 'success', summary: 'Succès', detail: 'Instructions de réinitialisation envoyées'});
      setStep('reset');
    } catch (error) {
      console.error('Password reset request failed', error);
      toast.current?.show({severity: 'error', summary: 'Erreur', detail: 'Échec de l\'envoi des instructions'});
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);
    try {
      await api.post('/reset-password/merchant', { 
        email, 
        pin, 
        password: newPassword, 
        password_confirmation: confirmPassword 
      });
      toast.current?.show({severity: 'success', summary: 'Succès', detail: 'Mot de passe réinitialisé avec succès'});
      setStep('success');
    } catch (error) {
      console.error('Password reset failed', error);
      toast.current?.show({severity: 'error', summary: 'Erreur', detail: 'Échec de la réinitialisation du mot de passe'});
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, setter: React.Dispatch<React.SetStateAction<string>>) => {
    const { name, value } = e.target;
    setter(value);
    setErrors(prev => ({ ...prev, [name]: '' }));
  };

  const renderRequestForm = () => (
    <form onSubmit={handleResetRequest} className="space-y-6">
      <div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
          Adresse e-mail
        </label>
        <input
          type="email"
          id="email"
          name="email"
          value={email}
          onChange={(e) => handleInputChange(e, setEmail)}
          className={`mt-1 block w-full rounded-md border ${errors.email ? 'border-red-500' : 'border-gray-300'} px-3 py-2 shadow-sm focus:border-teal-500 focus:outline-none focus:ring-teal-500`}
          placeholder="Entrez votre adresse e-mail"
        />
        {errors.email && <p className="mt-2 text-sm text-red-600">{errors.email}</p>}
      </div>
      <button
        type="submit"
        disabled={isLoading}
        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 disabled:opacity-50"
      >
        {isLoading ? 'Envoi en cours...' : 'Réinitialiser le mot de passe'}
      </button>
    </form>
  );

  const renderResetForm = () => (
    <form onSubmit={handleResetPassword} className="space-y-6">
      <div>
        <label htmlFor="pin" className="block text-sm font-medium text-gray-700">
          Code PIN
        </label>
        <input
          type="text"
          id="pin"
          name="pin"
          value={pin}
          onChange={(e) => handleInputChange(e, setPin)}
          className={`mt-1 block w-full rounded-md border ${errors.pin ? 'border-red-500' : 'border-gray-300'} px-3 py-2 shadow-sm focus:border-teal-500 focus:outline-none focus:ring-teal-500`}
          placeholder="Entrez le code PIN reçu par e-mail"
        />
        {errors.pin && <p className="mt-2 text-sm text-red-600">{errors.pin}</p>}
      </div>
      <div>
        <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700">
          Nouveau mot de passe
        </label>
        <div className="relative mt-1">
          <input
            type={showNewPassword ? 'text' : 'password'}
            id="newPassword"
            name="newPassword"
            value={newPassword}
            onChange={(e) => handleInputChange(e, setNewPassword)}
            className={`block w-full rounded-md border ${errors.newPassword ? 'border-red-500' : 'border-gray-300'} px-3 py-2 shadow-sm focus:border-teal-500 focus:outline-none focus:ring-teal-500`}
            placeholder="Entrez votre nouveau mot de passe"
          />
          <button
            type="button"
            onClick={() => setShowNewPassword(!showNewPassword)}
            className="absolute inset-y-0 right-0 flex items-center pr-3"
          >
            {showNewPassword ? <EyeOff className="h-5 w-5 text-gray-400" /> : <Eye className="h-5 w-5 text-gray-400" />}
          </button>
        </div>
        {errors.newPassword && <p className="mt-2 text-sm text-red-600">{errors.newPassword}</p>}
      </div>
      <div>
        <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
          Confirmer le mot de passe
        </label>
        <div className="relative mt-1">
          <input
            type={showConfirmPassword ? 'text' : 'password'}
            id="confirmPassword"
            name="confirmPassword"
            value={confirmPassword}
            onChange={(e) => handleInputChange(e, setConfirmPassword)}
            className={`block w-full rounded-md border ${errors.confirmPassword ? 'border-red-500' : 'border-gray-300'} px-3 py-2 shadow-sm focus:border-teal-500 focus:outline-none focus:ring-teal-500`}
            placeholder="Confirmez votre nouveau mot de passe"
          />
          <button
            type="button"
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            className="absolute inset-y-0 right-0 flex items-center pr-3"
          >
            {showConfirmPassword ? <EyeOff className="h-5 w-5 text-gray-400" /> : <Eye className="h-5 w-5 text-gray-400" />}
          </button>
        </div>
        {errors.confirmPassword && <p className="mt-2 text-sm text-red-600">{errors.confirmPassword}</p>}
      </div>
      <button
        type="submit"
        disabled={isLoading}
        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 disabled:opacity-50"
      >
        {isLoading ? 'Réinitialisation en cours...' : 'Réinitialiser le mot de passe'}
      </button>
    </form>
  );

  return (
    <div className="flex min-h-screen">
      <Toast ref={toast} />
      {/* Pattern Side */}
      <div className="hidden lg:flex lg:w-1/2 bg-teal-600 relative overflow-hidden">
        <div className="absolute inset-0" style={{
          backgroundImage: "url('/pattern.png')",
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }} />
      </div>

      {/* Forgot Password Form Side */}
      <div className="w-full lg:w-1/2 flex flex-col justify-center px-4 sm:px-6 lg:px-8 py-12">
        <div className="max-w-md w-full mx-auto">
          <div className="mb-6">
           <img className="w-[50%]  mx-auto text-teal-600" src='/dabablane-logo.png' />
          </div>
          
          <h2 className="text-2xl font-bold mb-8 text-gray-900">
            {step === 'request' ? 'Mot de passe oublié' : step === 'reset' ? 'Réinitialiser le mot de passe' : 'Mot de passe réinitialisé'}
          </h2>
          
          {step === 'request' && renderRequestForm()}
          {step === 'reset' && renderResetForm()}
          {step === 'success' && (
            <div className="text-center">
              <p className="text-green-600 mb-4">Votre mot de passe a été réinitialisé avec succès.</p>
              <p className="text-gray-600 mb-4">Vous pouvez maintenant vous connecter avec votre nouveau mot de passe.</p>
              <button
                onClick={() => navigate('/login')}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
              >
                Retour à la connexion
              </button>
            </div>
          )}

          {step !== 'success' && (
            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">Ou</span>
                </div>
              </div>

              <div className="mt-6">
                <button
                  type="button"
                  onClick={() => navigate('/login')}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-coral-500 hover:bg-coral-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-coral-500"
                >
                  Retour à la connexion
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}