import React, { useState, useEffect, useRef } from 'react';
import { Crown, Upload, Edit2, Check, AlertTriangle, X, Eye, EyeOff } from 'lucide-react';
import api from '../utils/api';
import { useAuth } from '../contexts/AuthContext';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import config from '../config/config';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

mapboxgl.accessToken = config.mapBoxToken;

interface FormData {
  responsibleName: string;
  mobile: string;
  phone: string;
  email: string;
  raison_social: string;
  description: string;
  address: string;
  addressSupplement: string;
  ice: string;
  rc: string;
  tva_id: number;
  tva: number;
  rib: string;
  latitude: number;
  longitude: number;
}

interface InputProps {
  label: string;
  name: string;
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  disabled: boolean;
}

interface TextAreaProps {
  label: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  disabled: boolean;
}

interface UploadBoxProps {
  text: string;
  currentImage?: string | null;
  onUpload: (file: File) => void;
  onReset: () => void;
}

interface TvaOption {
  tva_id: number;
  name: string;
  taux: string;
  created_at: string;
  updated_at: string | null;
}

function UploadBox({ text, currentImage, onUpload, onReset }: UploadBoxProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setImageError(false);
  }, [currentImage]);

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      onUpload(file);
    }
  };

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleImageError = () => {
    setIsLoading(false);
    setImageError(true);
  };

  return (
    <div className="border-2 border-dashed border-blue-300 rounded-lg p-6 flex flex-col items-center justify-center bg-blue-50 relative">
      {currentImage ? (
        <>
          {isLoading && (
            <div className="absolute inset-0 flex items-center justify-center bg-gray-200 animate-pulse">
              <span className="text-gray-500">Chargement...</span>
            </div>
          )}
          <img
            src={currentImage}
            alt={text}
            className={`object-cover mb-4 rounded-lg ${isLoading ? 'hidden' : 'block'}`}
            onLoad={handleImageLoad}
            onError={handleImageError}
          />
          {imageError && (
            <div className="text-red-500 mb-4">Erreur de chargement de l'image</div>
          )}
          <Button 
            icon="pi pi-times" 
            className="p-button-rounded p-button-text absolute top-2 right-2" 
            onClick={onReset} 
          />
        </>
      ) : (
        <Upload className="w-8 h-8 text-blue-500 mb-2" />
      )}
      <Button onClick={handleClick}>
        <p className="text-blue-600 mb-2">{text}</p>
      </Button>
      <input
        type="file"
        ref={fileInputRef}
        className="hidden"
        onChange={handleFileChange}
        accept="image/*"
      />
    </div>
  );
}

function Input({ label, name, value, onChange, error, disabled }: InputProps) {
  return (
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-1">{label}</label>
      <input
        type="text"
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        className={`w-full px-3 py-2 border ${error ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 ${disabled ? 'bg-gray-100' : ''}`}
      />
      {error && <p className="mt-1 text-sm text-red-600">{error}</p>}
    </div>
  );
}

function TextArea({ label, name, value, onChange, disabled }: TextAreaProps) {
  return (
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-1">{label}</label>
      <textarea
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        className={`w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 ${disabled ? 'bg-gray-100' : ''}`}
        rows={4}
      />
    </div>
  );
}

export default function ProfileContent() {
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    responsibleName: '',
    mobile: '',
    phone: '',
    email: '',
    raison_social: '',
    description: '',
    address: '',
    addressSupplement: '',
    ice: '',
    rc: '',
    tva_id: 0,
    tva: 0,
    rib: '',
    latitude: 0,
    longitude: 0,
  });
  const [errors, setErrors] = useState<Partial<FormData>>({});
  const [avatar, setAvatar] = useState<string | null>(null);
  const [banner, setBanner] = useState<string | null>(null);
  const [tvaOptions, setTvaOptions] = useState<TvaOption[]>([]);
  const { user, refreshUserData } = useAuth();
  const toast = useRef<Toast>(null);
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const marker = useRef<mapboxgl.Marker | null>(null);

  useEffect(() => {
    fetchMerchantProfile();
    fetchTvaOptions();
  }, []);

  useEffect(() => {
    if (mapContainer.current && !map.current) {
      initializeMap();
    }
  }, [formData.latitude, formData.longitude]);

  useEffect(() => {
    if (map.current) {
      map.current.on('click', handleMapClick);
    }

    return () => {
      if (map.current) {
        map.current.off('click', handleMapClick);
      }
    };
  }, [isEditing]);

  const initializeMap = () => {
    if (mapContainer.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [formData.longitude || -7.5898, formData.latitude || 33.5731],
        zoom: 9
      });

      map.current.on('load', () => {
        if (formData.latitude && formData.longitude) {
          addMarker(formData.latitude, formData.longitude);
        }
      });
    }
  };

  const addMarker = (lat: number, lng: number) => {
    if (map.current) {
      if (marker.current) {
        marker.current.remove();
      }
      marker.current = new mapboxgl.Marker()
        .setLngLat([lng, lat])
        .addTo(map.current);
    }
  };

  const handleMapClick = (e: mapboxgl.MapMouseEvent) => {
    if (isEditing) {
      const { lng, lat } = e.lngLat;
      setFormData(prev => ({ ...prev, longitude: lng, latitude: lat }));
      addMarker(lat, lng);
    }
  };

  const fetchMerchantProfile = async () => {
    try {
      const response = await api.get('/merchant/merchant-profile');
      const merchantData = response.data.merchant;
      setFormData({
        responsibleName: merchantData.responsibleName || '',
        mobile: merchantData.mobile || '',
        phone: merchantData.phone || '',
        email: merchantData.email || '',
        raison_social: merchantData.raison_social || '',
        description: merchantData.description || '',
        address: merchantData.address || '',
        addressSupplement: merchantData.addressSupplement || '',
        ice: merchantData.ice || '',
        rc: merchantData.rc || '',
        tva_id: merchantData.tva_id || 0,
        tva: merchantData.tva || 0,
        rib: merchantData.rib || '',
        latitude: merchantData.latitude || 0,
        longitude: merchantData.longitude || 0,
      });
      addMarker(merchantData.latitude, merchantData.longitude);
      setAvatar(merchantData.avatar);
      setBanner(merchantData.banner);
    } catch (error) {
      console.error('Failed to fetch merchant profile:', error);
      toast.current?.show({severity: 'error', summary: 'Erreur', detail: 'Impossible de charger le profil'});
    }
  };

  const fetchTvaOptions = async () => {
    try {
      const response = await api.get('/tva-options');
      setTvaOptions(response.data);
    } catch (error) {
      console.error('Failed to fetch TVA options:', error);
      toast.current?.show({severity: 'error', summary: 'Erreur', detail: 'Impossible de charger les options TVA'});
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
    if (errors[name as keyof FormData]) {
      setErrors(prevErrors => ({ ...prevErrors, [name]: undefined }));
    }
  };

  const validateForm = () => {
    let newErrors: Partial<FormData> = {};
    if (!formData.responsibleName) newErrors.responsibleName = "Le nom du responsable est requis";
    if (!formData.mobile) newErrors.mobile = "Le numéro mobile est requis";
    if (formData.mobile && !/^(\+212|0)([ \-_/]*)(\d[ \-_/]*){9}$/.test(formData.mobile)) {
      newErrors.mobile = "Le numéro mobile n'est pas valide";
    }
    if (!formData.address) newErrors.address = "L'adresse est requise";
    if (!formData.ice) newErrors.ice = "L'ICE est requis";
    if (formData.ice && !/^\d{15}$/.test(formData.ice)) {
      newErrors.ice = "L'ICE doit contenir 15 chiffres";
    }
    if (!formData.rc) newErrors.rc = "Le RC est requis";
    if (!formData.rib) newErrors.rib = "Le RIB est requis";
    if (formData.rib && !/^\d{24}$/.test(formData.rib)) {
      newErrors.rib = "Le RIB doit contenir 24 chiffres";
    }
    if (!formData.latitude || !formData.longitude) {
      newErrors.address = "Veuillez sélectionner votre emplacement sur la carte";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = async () => {
    if (validateForm()) {
      try {
        await api.put('/merchant/update-profile', formData);
        setIsEditing(false);
        await refreshUserData();
        toast.current?.show({severity: 'success', summary: 'Succès', detail: 'Profil mis à jour avec succès'});
      } catch (error) {
        console.error('Failed to update profile:', error);
        toast.current?.show({severity: 'error', summary: 'Erreur', detail: 'Impossible de mettre à jour le profil'});
      }
    } else {
      toast.current?.show({severity: 'error', summary: 'Erreur', detail: 'Veuillez corriger les erreurs dans le formulaire'});
    }
  };

  const handleFileUpload = async (file: File, type: 'avatar' | 'banner') => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', type);

    try {
      const response = await api.post('/merchant/upload-image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (type === 'avatar') {
        setAvatar(response.data.url);
      } else {
        setBanner(response.data.url);
      }
      toast.current?.show({severity: 'success', summary: 'Succès', detail: `${type === 'avatar' ? 'Avatar' : 'Bannière'} mis(e) à jour avec succès`});
    } catch (error) {
      console.error(`Failed to upload ${type}:`, error);
      toast.current?.show({severity: 'error', summary: 'Erreur', detail: `Impossible de mettre à jour ${type === 'avatar' ? 'l\'avatar' : 'la bannière'}`});
    }
  };

  const handleImageReset = async (type: 'avatar' | 'banner') => {
    try {
      await api.delete(`/merchant/reset-image/${type}`);
      if (type === 'avatar') {
        setAvatar(null);
      } else {
        setBanner(null);
      }
      toast.current?.show({severity: 'success', summary: 'Succès', detail: `${type === 'avatar' ? 'Avatar' : 'Bannière'} réinitialisé(e) avec succès`});
    } catch (error) {
      console.error(`Failed to reset ${type}:`, error);
      toast.current?.show({severity: 'error', summary:  'Erreur', detail: `Impossible de réinitialiser ${type === 'avatar' ? 'l\'avatar' : 'la bannière'}`});
    }
  };

  return (
    <div className="space-y-6">
      <Toast ref={toast} />
      {!user?.isProfileComplete && (
        <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-6" role="alert">
          <div className="flex">
            <div className="py-1">
              <AlertTriangle className="h-6 w-6 text-yellow-500 mr-4" />
            </div>
            <div>
              <p className="font-bold">Attention</p>
              <p>Votre profil n'est pas complet. Veuillez remplir tous les champs obligatoires pour activer votre compte.</p>
            </div>
          </div>
        </div>
      )}

      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Profil</h1>
        <div className="flex space-x-4">
                  <a href="/abonement" className="flex items-center px-4 py-2 bg-teal-600 text-white rounded-md">
        <svg className="w-4 h-4 mr-2 text-[#FFB743]" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.12755 16.2327L0.758799 7.33624C0.657549 6.67924 1.40605 6.23074 1.9378 6.62974L6.2023 9.82774C6.31315 9.91078 6.43975 9.97036 6.57439 10.0028C6.70903 10.0353 6.84888 10.04 6.98539 10.0166C7.12191 9.99326 7.25223 9.94231 7.36839 9.86689C7.48456 9.79147 7.58414 9.69317 7.66105 9.57799L11.2115 4.25299C11.5865 3.69049 12.413 3.69049 12.788 4.25299L16.3385 9.57799C16.4155 9.69317 16.515 9.79147 16.6312 9.86689C16.7474 9.94231 16.8777 9.99326 17.0142 10.0166C17.1507 10.04 17.2906 10.0353 17.4252 10.0028C17.5598 9.97036 17.6865 9.91078 17.7973 9.82774L22.0618 6.62974C22.5943 6.23074 23.3421 6.67924 23.2408 7.33624L21.872 16.2327H2.12755ZM20.8423 21.1692H3.1573C3.02207 21.1692 2.88817 21.1426 2.76323 21.0909C2.6383 21.0391 2.52478 20.9633 2.42916 20.8676C2.23604 20.6745 2.12755 20.4126 2.12755 20.1395V17.8782H21.872V20.1395C21.872 20.708 21.4108 21.1692 20.8423 21.1692Z" fill="#FFB743"/>
          </svg>
          Activer PREMIUM
        </a>
          {isEditing ? (
            <button onClick={handleSave} className="px-4 py-2 bg-black text-white rounded-md flex items-center">
              <Check className="w-4 h-4 mr-2" />
              Enregistrer
            </button>
          ) : (
            <button onClick={() => setIsEditing(true)} className="px-4 py-2 bg-black text-white rounded-md flex items-center">
              <Edit2 className="w-4 h-4 mr-2" />
              Modifier
            </button>
          )}
        </div>
      </div>

      <div className="bg-white p-6 rounded-lg shadow">
        <h2 className="text-lg font-semibold mb-4">Logo & photo de couverture</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <UploadBox
            text="Charger le logo"
            currentImage={avatar ? `${config.endpoints.imgAvatar}/${avatar}` : null}
            onUpload={(file) => handleFileUpload(file, 'avatar')}
            onReset={() => handleImageReset('avatar')}
          />
          <UploadBox
            text="Charger la photo de couverture"
            currentImage={banner ? `${config.endpoints.imgBanner}/${banner}` : null}
            onUpload={(file) => handleFileUpload(file, 'banner')}
            onReset={() => handleImageReset('banner')}
          />
        </div>
      </div>

      <div className="bg-white p-6 rounded-lg shadow">
        <h2 className="text-lg font-semibold mb-4">Informations de contact</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
          <Input
            label="Nom du responsable"
            name="responsibleName"
            value={formData.responsibleName}
            onChange={handleInputChange}
            error={errors.responsibleName}
            disabled={!isEditing}
          />
          <Input
            label="Mobile/Whatsapp"
            name="mobile"
            value={formData.mobile}
            onChange={handleInputChange}
            error={errors.mobile}
            disabled={!isEditing}
          />
          <Input
            label="Téléphone"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            disabled={!isEditing}
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <Input
            label="Adresse e-mail"
            name="email"
            value={formData.email}
            disabled={true}
          />
          <Input
            label="Raison sociale"
            name="raison_social"
            value={formData.raison_social}
            disabled={true}
          />
          <TextArea
            label="Description"
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            disabled={!isEditing}
          />
        </div>
      </div>

      <div className="bg-white p-6 rounded-lg shadow">
        <h2 className="text-lg font-semibold mb-4">Adresse & Localisation</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="space-y-4">
            <Input
              label="Adresse"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
              error={errors.address}
              disabled={!isEditing}
            />
            <Input
              label="Supplément d'adresse"
              name="addressSupplement"
              value={formData.addressSupplement}
              onChange={handleInputChange}
              disabled={!isEditing}
            />
          </div>
          <div>
            <div ref={mapContainer} className="h-64 rounded-lg" />
            {isEditing && (
              <p className="mt-2 text-sm text-gray-600">
                Cliquez sur la carte pour définir votre emplacement.
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="bg-white p-6 rounded-lg shadow">
        <h2 className="text-lg font-semibold mb-4">Paiement & fiscalité</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
          <Input
            label="ICE"
            name="ice"
            value={formData.ice}
            onChange={handleInputChange}
            error={errors.ice}
            disabled={!isEditing}
          />
          <Input
            label="RC"
            name="rc"
            value={formData.rc}
            onChange={handleInputChange}
            error={errors.rc}
            disabled={!isEditing}
          />
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">TVA</label>
            <Dropdown
              value={formData.tva}
              options={tvaOptions}
              onChange={(e) => setFormData(prev => ({ ...prev, tva: e.value }))}
              optionLabel="name"
              optionValue="tva_id"
              placeholder="Sélectionnez une option TVA"
              className={`w-full ${!isEditing ? 'p-disabled' : ''}`}
              disabled={!isEditing}
            />
          </div>
        </div>
        <Input
          label="RIB"
          name="rib"
          value={formData.rib}
          onChange={handleInputChange}
          error={errors.rib}
          disabled={!isEditing}
        />
      </div>
    </div>
  );
}